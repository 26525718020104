import React from 'react';
import * as classes from './service-sub.module.css';

const ServiceSub = (props) => {
    if (props.lefty) {
        return (
            <div className={classes.containerLeft}>
                
                <div className={classes.contentLeft}>
                    <div className={classes.image}></div>
                </div>
                <div className={classes.contentRight}>
                    <div className={classes.description}>
                        <h2><span className={classes.colorDarkBlue}>{props.titleWhite}</span><span className={classes.colorBlue}>{props.titleBlue}</span></h2>
                        <p className={classes.descriptionElement}>{props.desc}</p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={classes.containerRight}>
                <div className={classes.contentRightyLeft}>
                    <div className={classes.imageRight}></div>
                </div>
                <div className={classes.contentRightyRight}>
                    <div className={classes.descriptionRighty}>
                        <h2><span className={classes.colorDarkBlue}>{props.titleWhite}</span><span className={classes.colorBlue}>{props.titleBlue}</span></h2>
                        <p className={classes.descriptionElement}>{props.desc}</p>
                    </div>
                </div>
            </div>
        );
    }
    
    
}

export default ServiceSub;