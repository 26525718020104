import React from 'react';
import * as classes from './service-item.module.css';
const ServiceItem = (props) => {
    if (props.lefty) {
        return (
            <div className={classes.container}>
                <div className={classes.contentLeft}>
                    <div className={classes.gradientBackground}>
                        <div className={classes.image}></div>
                    </div>
                </div>
                <div className={classes.contentRight}>
                    <div className={classes.description}>
                        <h2><span className={classes.colorBlue}>{props.titleBlue}</span><span className={classes.colorDarkBlue}>{props.titleWhite}</span></h2>
                        <p className={classes.descriptionElement}>{props.desc}</p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={classes.containerRighty}>
                <div className={classes.contentRightRighty}>
                    <div className={classes.gradientBackgroundRighty}>
                        <div className={classes.imageRighty}></div>
                    </div>
                </div>
                <div className={classes.contentLeftRighty}>
                    <div className={classes.descriptionRighty}>
                        <h2><span className={classes.colorBlue}>{props.titleBlue}</span><span className={classes.colorDarkBlue}>{props.titleWhite}</span></h2>
                        <p className={classes.descriptionElement}>{props.desc}</p>
                    </div>
                </div>
            </div>
        );
    }

}

export default ServiceItem;