// extracted by mini-css-extract-plugin
export var containerLeft = "service-sub-module--containerLeft--aV9ZU";
export var containerRight = "service-sub-module--containerRight---qXgi";
export var contentLeft = "service-sub-module--contentLeft--CdR3+";
export var contentRight = "service-sub-module--contentRight--gfKLy";
export var contentRightyLeft = "service-sub-module--contentRightyLeft--UQivR";
export var contentRightyRight = "service-sub-module--contentRightyRight--zVei9";
export var image = "service-sub-module--image--bTbyv";
export var imageRight = "service-sub-module--imageRight--mdSbz";
export var description = "service-sub-module--description--nvvKA";
export var descriptionRighty = "service-sub-module--descriptionRighty--1utMg";
export var descriptionElement = "service-sub-module--descriptionElement--wC9OQ";
export var colorBlue = "service-sub-module--colorBlue--xrPCH";
export var colorDarkBlue = "service-sub-module--colorDarkBlue--+ls6Q";