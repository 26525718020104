// extracted by mini-css-extract-plugin
export var container = "service-item-module--container--YTPIW";
export var containerRighty = "service-item-module--containerRighty--ib8fS";
export var contentLeft = "service-item-module--contentLeft--MFNAi";
export var contentRightRighty = "service-item-module--contentRightRighty--pqaIU";
export var gradientBackground = "service-item-module--gradientBackground--PApI+";
export var gradientBackgroundRighty = "service-item-module--gradientBackgroundRighty--WXDV0";
export var contentRight = "service-item-module--contentRight--UO1MD";
export var contentLeftRighty = "service-item-module--contentLeftRighty--GPSYU";
export var description = "service-item-module--description--Rf9xF";
export var descriptionRighty = "service-item-module--descriptionRighty--SPB6s";
export var image = "service-item-module--image--Yuky-";
export var imageRighty = "service-item-module--imageRighty--Jnj3L";
export var colorBlue = "service-item-module--colorBlue--4KJ3O";
export var colorDarkBlue = "service-item-module--colorDarkBlue--5iufD";
export var boldDesctiptionTitle = "service-item-module--boldDesctiptionTitle---1JHT";
export var descriptionElement = "service-item-module--descriptionElement--B62N7";