import React from 'react';
import * as classes from './about-us.module.css';

import AboutUsItem from './about-us-item';

const AboutUs = () => {
    return (
        <div className={classes.container}>
            <p className={classes.subTitle}>会社概要</p>
            <h2 className={classes.title} >About Us</h2>
            <AboutUsItem subTitle={ "会社名" } content={ "BLINK株式会社" } />
            <AboutUsItem subTitle={"代表取締役"} content={"浅井悠佑"} />
            <AboutUsItem subTitle={"設立日"} content={"2021年11月12日"} />
            <AboutUsItem subTitle={"事業内容"} content={"実世界デジタルツイン構築ソフトウェアの研究開発 \n自動配送ロボットの研究開発"} />
            <AboutUsItem subTitle={"資本金"} content={"100万円"} />
            <AboutUsItem subTitle={"所在地"} content={"〒468-0061 \n愛知県名古屋市天白区八事天道201 \nアーバンハイツ八事302"} />
        </div>
    );
}

export default AboutUs;