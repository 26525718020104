import React from 'react';
import * as classes from './uni.module.css';
import Aux from '../hoc/aux';

const Uni = (props) => {
    return (
        <Aux>
            <h4 className={classes.title}>{props.title}</h4>
            <div className={classes.containerLeft}>
                <div className={classes.contentLeft}>
                    <div className={classes.image}></div>
                </div>
                <div className={classes.contentRight}>
                    <div className={classes.description}>
                        <p className={classes.descriptionElement}>BLINK株式会社は<a href="https://ucl.nuee.nagoya-u.ac.jp/">名古屋大学河口研究室</a>所属の学生が起業した会社です。企業様と共同研究を行っていくなかで、研究成果を事業化することを念頭にしています。名古屋大学河口研究室では様々な種類のセンサーを用いた実世界センシングやセンサーから得られたデータの分析を研究分野の1つとしています。また、弊社の代表取締役である浅井悠佑は、名古屋大学で運営されている卓越大学院の1つである<a href="https://www.tmi.mirai.nagoya-u.ac.jp/">超学際協働による移動イノベーション(TMI: Transdisciplinary Mobility Innovation)プログラム</a>の履修生(2期生)です。</p>
                    </div>
                </div>
            </div>
        </Aux>
    );
}

export default Uni;