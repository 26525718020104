import React from 'react';
import { Link } from 'gatsby';
import Aux from '../hoc/aux';

import * as classes from './hero.module.css';

const Hero = (props) => {
    return (
        <Aux>
            <div className={classes.container}>
                <div className={classes.heroImageLayer}></div>
                <div className={classes.hero}>
                    <h1 className={classes.mainTitle}><span className={classes.colorBlue}>{props.tune}</span><span className={classes.colorDarkBlue}>{props.middleTitle}</span><span className={classes.colorBlue}>{props.mood}</span></h1>
                    <p className={classes.subTitle}>{props.subTitle}</p>
                    <a href="#service" className={classes.downloadButton}>{props.download}</a>
                </div>
            </div>
        </Aux>
    );
}

export default Hero;