import React from 'react';
import * as classes from './service-title.module.css';

const ServiceTitle = (props) => {
    return (
        <div className={ classes.container }>
            <p id={"service"} className={ classes.subTitle }>{ props.subTitle }</p>
            <h2 className={ classes.title } >{ props.title }</h2>
            <h2 className={ classes.contentContainer }><span className={ classes.content1 }>{ props.content1 }</span><span className={ classes.and }>{"\n&\n"}</span><span className={ classes.content2 }>{ props.content2 }</span></h2>
        </div>
    );
}

export default ServiceTitle;