import React from 'react';
import * as classes from './about-us-item.module.css';

const AboutUsItem = (props) => {
    return (
        <div className={classes.container}>
            <p className={classes.subTitle}>{ props.subTitle }</p>
            <p className={classes.content} >{ props.content }</p>
        </div>
    );
}

export default AboutUsItem;