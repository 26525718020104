import React from 'react';
import Header from './header';
import Aux from '../hoc/aux';

import * as classes from './layout.module.css';

const Layout = (props) => {
    return (
        <Aux>
            <nav className={classes.layout}>
                <Header />
            </nav>
            <main className={classes.layout}>
                { props.children }
            </main>
            <footer className={classes.layout}>
            </footer>
        </Aux>
    );
}

export default Layout;