import React from 'react';
import { Link } from 'gatsby';
import Aux from '../hoc/aux';

import * as classes from './header.module.css';


const Header = () => {
    return (
        <Aux>
            <div className={classes.container}>
                <Link to="/" className={classes.icon}>blink</Link>
                <Link to="/" className={classes.appName}></Link>
            </div>
        </Aux>
    );
}

export default Header;