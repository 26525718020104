import React from 'react';
import * as classes from './contact.module.css';

const Contact = () => {
    return (
        <div className={classes.container}>
            <p className={classes.subTitle}>お問い合わせ先</p>
            <h2 className={classes.title} >Contact</h2>
            <p className={classes.contactMsg}>お気軽にお問い合わせください。</p>
            <div className={classes.mail}>
                <a href="mailto:contact@blinktech.co.jp">contact@blinktech.co.jp</a>
            </div>
            
        </div>
    );
}

export default Contact;