import React from "react";
import Layout from "../components/layout";

import Hero from '../components/hero';
import ServiceTitle from "../components/service-title";
import ServiceItem from "../components/service-item";
import ServiceSub from "../components/service-sub";
import Uni from "../components/uni";
import AboutUs from "../components/about-us";
import Contact from "../components/contact";

const Home = () => {

  const tune = "Digitalizing";
  const middleTitle = " the real world for ";
  const mood = "automation";
  const subTitle = "建物内のデジタルツイン構築により、業務改善・効率化を行い、ロボティクス技術を用いた自動化でさらなる成長へ";
  const download = "もっと詳しく";

  const serviceSubTitle = "サービス";
  const serviceTitle = "Service";
  const serviceContent1 = "デジタルツイン";
  const serviceContent2 = "ロボティクス"

  const s1TitleBlue = "3次元";
  const s1TitleWhite = "地図作成";
  const desc1 = "3次元地図はデジタルツインの基盤となるものです。3次元スキャナーを用いて実際の施設や建物の3次元情報を取得したあとに、用途に応じて点群地図・メッシュ地図・Low Poly地図などをそれぞれ作成します。デジタルデータとして建物情報を保有しておくことで、作業記録のデータなどを用いたシミュレーションを行う際に、より現実に近いシミュレーションが可能となります。";

  const s2TitleBlue = "リアルタイム";
  const s2TitleWhite = "センシング";
  const desc2 = "ヒトやモノの動きを環境に固定したカメラやLiDARセンサーを用いてリアルタイムで検出・トラッキングします。施設内の現在の状態を一目で可視化・確認することができます。継続的にセンシングを行うことで作業の導線を可視化して作業効率化につなげることや、施策の検証を事後的に行うことが可能となります。";

  const s3TitleWhite = "データ";
  const s3TitleBlue = "の可視化・分析"
  const desc3 = "センサーから得られたデータを蓄積して、作業者一人ひとりやフロア毎など様々な粒度で可視化を行います。データを分析することにより、作業フローの見直し・改善に役立てることができます。また、作業フローを変更する前にデジタルツイン上でシミュレーションを実行することで、仮想的に作業パフォーマンスの向上を検証可能にします。"

  const s4TitleWhite = "自動";
  const s4TitleBlue = "配送ロボット";
  const desc4 = "デジタルツインから得られたリアルタイムな情報を用いて自動配送ロボット(Autonomous Mobile Robot)が効率的に運用するための研究開発を行っています。複数のフロア間を移動するためにエレベーターなどのインフラとの連携や、アーム搭載型配送ロボットを用いた遠隔操作技術の開発も行っています。"

  const uniTitle = "大学の研究室から生まれた会社です";

  return (
    <Layout>
      <Hero tune={tune} middleTitle={middleTitle} mood={mood} subTitle={subTitle} download={download} />
      <ServiceTitle subTitle={serviceSubTitle} title={serviceTitle} content1={serviceContent1} content2={serviceContent2}/>
      <ServiceItem lefty={true} titleBlue={s1TitleBlue} titleWhite={s1TitleWhite} desc={desc1}/>
      <ServiceItem lefty={false} titleBlue={s2TitleBlue} titleWhite={s2TitleWhite} desc={desc2} />
      <ServiceSub lefty={true} titleWhite={s3TitleWhite} titleBlue={s3TitleBlue} desc={desc3}/>
      <ServiceSub lefty={false} titleWhite={s4TitleWhite} titleBlue={s4TitleBlue} desc={desc4} />
      <Uni title={uniTitle} />
      <AboutUs />
      <Contact />
    </Layout>
  );
}

export default Home;